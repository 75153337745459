import { Col, Empty, Flex, Row } from 'antd';
import React, { useState } from 'react';
import styles from './UserDevices.module.scss';
import DisplayDateTime from '../../../utils/time/display_time';
import { Switch } from '../../../common';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';
import { userDeviceRevokeApi, userDeviceUnRevokeApi } from '../../../api/users-api';

const DeviceCard = ({ device = {}, onSuccess = () => {} }) => {
  const [deviceAccess, setDeviceAccess] = useState(device?.revoked ?? false);
  const [loading, setLoading] = useState(false);

  const handleDeviceAccessChange = async (toggle) => {
    setDeviceAccess(toggle);
    setLoading(true);
    const updateDeviceAccessApi = toggle ? userDeviceRevokeApi : userDeviceUnRevokeApi;
    try {
      const res = await updateDeviceAccessApi(device?.id);
      if (res && res?.status) {
        onSuccess();
      } else {
        toast.error(getErrorString(res));
        setDeviceAccess(!toggle);
      }
    } catch (error) {
      toast.error(getErrorString(error));
      setDeviceAccess(!toggle);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex vertical className={styles.deviceCardContainer} gap={20}>
      <Flex vertical gap={6}>
        <div className="text-2">Device Name</div>
        <div className="subtitle-1">{device?.device_name}</div>
      </Flex>
      <Row gutter={[10, 30]}>
        <Col span={12}>
          <div className="text-2 mb-2">Total SignIn</div>
          <div className="text-1">{device?.total_sign_in}</div>
        </Col>
        <Col span={12}>
          <div className="text-2 mb-2">Last SignIn At</div>
          <div className="text-1">{DisplayDateTime(device?.last_sign_in_at) ?? '-'}</div>
        </Col>
      </Row>
      <Switch label="Revoke Device" checked={deviceAccess} handleChange={handleDeviceAccessChange} loading={loading} />
    </Flex>
  );
};

const UserDevicesBody = ({ devicesData = [], onSuccess = () => {} }) => {
  return (
    <Flex vertical className={styles.userDevicesBodyContainer} style={{ minHeight: '100%' }} gap={24}>
      {devicesData?.length > 0 ? (
        devicesData?.map((device) => <DeviceCard key={device?.id} device={device} onSuccess={onSuccess} />)
      ) : (
        <Empty />
      )}
    </Flex>
  );
};

export default UserDevicesBody;
