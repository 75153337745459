import APIController from '../core-api-controller';

export const loginApi = (data) => {
  const path = `${APIController.baseUrl}/login`;
  return APIController.postCallback(path, data, true);
};

export const logoutApi = () => {
  const path = `${APIController.baseUrl}/logout`;
  return APIController.deleteCallback(path, {}, true);
};
