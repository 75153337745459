import React, { Fragment, useRef, useState } from 'react';
import { Button, Modal, Tooltip } from '../../../common';
import CreateTicketModalBody from './CreateTicketModalBody';
import { createLeadSupportTicket } from '../../../api/leads-api';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';
import { PERMISSIONS_STRUCT } from '../../../constants/permissions';
import Permission from '../../permission/Permission';

const CreateTicketModal = ({
  disabled = false,
  assignees = [],
  types = [],
  leadId = '',
  onSuccessCreate = () => {},
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const handleSubmit = async (values) => {
    if (!values?.type?.id || !leadId) return;
    try {
      setLoading(true);
      const payload = {
        ticket: {
          type_id: values?.type?.id || '',
          assignee_id: values?.assignee?.id || '',
          description: values?.description || '',
        },
      };

      const res = await createLeadSupportTicket(payload, leadId);
      if (res && res?.status) {
        onSuccessCreate();
        toast.success('Ticket Created Successfully');
        setModalOpen(false);
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Permission models={PERMISSIONS_STRUCT.LEAD.NAME} actions={PERMISSIONS_STRUCT.LEAD.ACTIONS.OPEN_TICKET}>
        <Tooltip title="Create Ticket">
          <Button onClick={() => setModalOpen(true)} icon={'BsPlus'} size={'medium'} disabled={disabled} />
        </Tooltip>
      </Permission>

      <Modal
        title="Create Support Ticket"
        open={modalOpen}
        ModalBody={
          <CreateTicketModalBody assignees={assignees} types={types} handleSubmit={handleSubmit} ref={formRef} />
        }
        handleCancel={() => setModalOpen(false)}
        handleOk={() => {
          formRef?.current?.submitForm();
        }}
        okButtonProps={{ loading }}
        okText="Create"
        showActions={true}
        destroyOnClose={true}
        width={700}
      />
    </Fragment>
  );
};

export default CreateTicketModal;
