const themeConfig = {
  hashed: false,
  cssVar: { key: 'elevate' },
  token: {
    fontFamily:
      "Inter, proxima-nova, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    colorPrimary: '#08b4c4',
  },
  components: {
    Button: {
      defaultShadow: 'none',
      dangerShadow: 'none',
      primaryShadow: 'none',
      fontWeight: '600',
    },
    DatePicker: {
      sizePopupArrow: 0,
    },
  },
};

export default themeConfig;
