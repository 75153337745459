import APIController from '../core-api-controller';

//Listing API's

export const fetchPatientsApi = (paramsObj = {}, paramsStr = '') => {
  const path = `${APIController.endpointApi}/patients${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};

//Detail Page API's

export const fetchPatientDetail = (patientId = '') => {
  const path = `${APIController.endpointApi}/patients/${patientId}`;
  return APIController.getApiCallback(path);
};

export const updatePatientDetail = (data, patientId = '') => {
  const path = `${APIController.endpointApi}/patients/${patientId}`;
  return APIController.putCallback(path, data);
};

export const createPatient = (data = {}) => {
  const path = `${APIController.endpointApi}/patients`;
  return APIController.postCallback(path, data);
};
