export const BASE_PATH = '/admin';

export const LEADS_PATH = '/leads';
export const LEAD_DETAIL_PATH = '/leads/:id/detail';

export const PATIENTS_PATH = '/patients';
export const PATIENT_EDIT_PATH = '/patients/:id/edit';
export const PATIENT_CREATE_PATH = '/patients/create';

export const ORDERS_PATH = '/orders';

export const CASES_PATH = '/cases';
export const CASE_DETAIL_PATH = '/cases/:id/detail';

export const STATUSES_PATH = '/statuses';
export const STATUS_EDIT_PATH = '/statuses/:id/edit';

export const PRODUCTS_PATH = '/products';

export const SUBSCRIPTIONS_PATH = '/subscriptions';

export const USERS_PATH = '/users';

export const TICKETS_PATH = '/tickets';

export const QUESTIONNAIRES_PATH = '/questionnaires';

export const VOUCHERS_PATH = '/vouchers';
