import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import NotFound from '../pages/404/not_found';
import Signin from '../pages/auth/signin';
import { getCookie } from '../utils/cookies-util';
import { AuthProvider } from '../contexts/auth-context';
import ElevateAdminRoutes from './ElevateAdminRoutes';
import { BASE_PATH } from '../constants/admin-paths';
import ProtectedRoute from '../components/protected_route/ProtectedRoute';

const ElevateRoutes = () => {
  const currentUser = getCookie('access-token');
  const [user, setUser] = useState(!!currentUser);
  const handleLogin = () => setUser(true);
  const handleLogout = () => setUser(false);

  return (
    <AuthProvider value={{ handleLogout }}>
      <Router basename={BASE_PATH}>
        <Toaster />
        <Routes>
          {/* <Route path="/" element={user ? <Navigate to={getAppPath()} /> : <Navigate to={LOGIN_PATH} />} /> */}

          <Route path="/signin" element={user ? <Navigate to={'/'} /> : <Signin handleLogin={handleLogin} />} />

          <Route
            path="/*"
            element={
              <ProtectedRoute user={user}>
                <ElevateAdminRoutes user={user} />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default ElevateRoutes;
