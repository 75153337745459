import React from 'react';
import cx from 'clsx';
import DataTable from 'react-data-table-component';
import CustomLoader from '../../utils/data_table_loader/custom_loader';
import { pageLimitOptions } from '../../constants/generic-lists';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { convertQueryObjToString, mapQueryStringToFilterObject } from '../../utils/filters-util';
import { ArrowDownIcon } from '../../common/all-icons/custom-icons';
import { Card } from '../../common';
import SelectedFilters from '../filters/selected-filters/SelectedFilters';
import { Flex } from 'antd';
import { TableLoader } from './TableLoader';
import TableEmpty from './TableEmpty';

const DataTableComponent = ({
  columns = [],
  tableData = [],
  progressPending = false,
  paginationObject = {},
  customClassName = '',
  filterChips = [],
  filtersList = [],
  rootPathUrl = '',
  persistTableHead = false,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { count: totalRecords, items, page: currentPage, pages: totalPages } = paginationObject || {};
  let limitPerPage = searchParams?.get('per_page') || process.env.REACT_APP_DEFAULT_PAGE_SIZE;
  const defaultPageProp = currentPage ? { paginationDefaultPage: currentPage } : {};

  const handleFiltersChange = (updatedFilterObject) => {
    navigate({
      pathname: location.pathname,
      search: convertQueryObjToString(updatedFilterObject),
    });
  };

  const onChangePage = (page) => {
    if (currentPage !== page) {
      const queryObj = mapQueryStringToFilterObject(location.search || '');
      const updatedFilterObject = { ...queryObj?.queryObj, page: page };
      handleFiltersChange(updatedFilterObject);
    }
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    const queryObj = mapQueryStringToFilterObject(location.search || '');
    const updatedFilterObject = { ...queryObj?.queryObj, per_page: newPerPage };
    handleFiltersChange(updatedFilterObject);
  };

  const onSortColumn = (column, sortDirection) => {
    const queryObj = mapQueryStringToFilterObject(location.search || '');
    const updatedFilterObject = { ...queryObj?.queryObj, 'q[s]': `${column.sortField}%20${sortDirection}` };
    handleFiltersChange(updatedFilterObject);
  };

  const customStyles = {
    responsiveWrapper: {
      style: {
        border: '1px solid var(--gray-200)',
        borderRadius: '8px',
      },
    },
    headCells: {
      style: {
        backgroundColor: '#F9FAFB',
        padding: '14px 16px 14px 16px',
        fontSize: '14px',
        color: 'var(--gray-500)',
      },
    },
    cells: {
      style: {
        padding: '14px 16px',
      },
    },
  };
  return (
    <Card>
      <Flex vertical gap={24}>
        <SelectedFilters filterChips={filterChips} filtersList={filtersList} rootPathUrl={rootPathUrl} />
        <div className="table-wrapper">
          <DataTable
            //data
            columns={columns}
            data={tableData}
            //style
            direction="auto"
            highlightOnHover
            noHeaderpagination
            persistTableHead={persistTableHead}
            pointerOnHover
            responsive
            subHeaderAlign="right"
            subHeaderWrap
            customStyles={customStyles}
            className={cx({
              'sticky-action-cells': columns[columns.length - 1]?.name === 'Actions',
              [customClassName]: true,
            })}
            sortIcon={<ArrowDownIcon />}
            progressPending={progressPending}
            progressComponent={<TableLoader columnSkeletons={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]} />}
            //pagination
            pagination
            paginationServer
            paginationPerPage={limitPerPage}
            paginationTotalRows={totalRecords}
            paginationRowsPerPageOptions={pageLimitOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            {...defaultPageProp}
            sortServer={true}
            onSort={onSortColumn}
            noDataComponent={<TableEmpty />}
            {...rest}
          />
        </div>
      </Flex>
    </Card>
  );
};

export default DataTableComponent;
