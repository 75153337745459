import APIController from '../core-api-controller';

//Products Listing API's

export const fetchProductsApi = (paramsObj = {}, paramsStr = '') => {
  const path = `${APIController.endpointApi}/products${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};

export const syncProductsApi = () => {
  const path = `${APIController.endpointApi}/products/sync`;
  return APIController.postCallback(path);
};
