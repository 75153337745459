import { useEffect, useState } from 'react';
import { COLUMN_TYPE_SLUGS } from '../../constants/active-column-constants.js';
import StatusButton from '../../utils/status/status_button.jsx';
import { fetchValueByDotOperator } from '../../utils/index.js';
import DisplayDateTime from '../../utils/time/display_time.js';
import { Avatar, TextWithCopy } from '../../common/index.js';
import { getActions } from '../../utils/actions-util/index.js';
import { SOURCE_CLASS_NAMES } from '../../constants/generic-constants.js';

const useTableColumns = ({
  activeColumns = [],
  activeColumnsLoading = false,
  onRowClick = () => {},
  actionsList = [],
}) => {
  const [tableColumns, setTableColumns] = useState([]);

  const getStatusColKey = (cellKey = '') => {
    if (cellKey === 'name') return 'status';
    return cellKey.replace('.name', '');
  };

  const getStatusColValue = (activeCol = {}, row = {}, columnKey = '') => {
    if (activeCol?.source_class === SOURCE_CLASS_NAMES.STATUS) {
      return <StatusButton status={{ ...row }} />;
    }

    return fetchValueByDotOperator(row, getStatusColKey(columnKey)) ? (
      <StatusButton status={fetchValueByDotOperator(row, getStatusColKey(columnKey))} />
    ) : (
      '-'
    );
  };

  const getArrayColValue = (row = {}, colKey = '') => {
    const arrVal = fetchValueByDotOperator(row, getStatusColKey(colKey));
    if (arrVal.length > 0) {
      return arrVal.map((val) => val?.name ?? val?.slug).join(', ');
    } else {
      return '-';
    }
  };

  const getColSelector = (activeCol = {}) => {
    const colKey = activeCol.key;
    switch (activeCol?.field_type) {
      case COLUMN_TYPE_SLUGS.STATUS:
        return (row) => fetchValueByDotOperator(row, getStatusColKey(colKey));
      default:
        return (row) => fetchValueByDotOperator(row, colKey);
    }
  };

  const getColSortFields = (acticeCol = {}) => {
    if (acticeCol?.sortable && acticeCol?.sortfield)
      return {
        sortable: true,
        sortField: acticeCol.sortfield,
      };
    return {};
  };

  const getColMinWidth = (activeCol = {}) => {
    if (activeCol?.min_width) {
      return { minWidth: `${activeCol.min_width}px` };
    } else {
      switch (activeCol?.field_type) {
        case COLUMN_TYPE_SLUGS.ARRAY:
          return { minWidth: '300px' };
        case COLUMN_TYPE_SLUGS.AVATAR_TEXT:
          return { minWidth: '200px' };
        case COLUMN_TYPE_SLUGS.ARRAY:
          return { minWidth: '220px' };
        default:
          return {};
      }
    }
  };

  const getColCell = (activeCol = {}) => {
    const columnKey = activeCol?.key;

    switch (activeCol?.field_type) {
      case COLUMN_TYPE_SLUGS.AVATAR_TEXT:
        return (row) => (
          <div onClick={(event) => onRowClick(row, event)}>
            {fetchValueByDotOperator(row, columnKey) ? (
              <>
                <Avatar name={fetchValueByDotOperator(row, columnKey)} />
                <span> {fetchValueByDotOperator(row, columnKey)}</span>
              </>
            ) : (
              '-'
            )}
          </div>
        );
      case COLUMN_TYPE_SLUGS.TEXT:
        return (row) => (
          <div data-tag="allowRowEvents" style={{ whiteSpace: 'pre-wrap' }}>
            {fetchValueByDotOperator(row, columnKey) ? fetchValueByDotOperator(row, columnKey) : '-'}
          </div>
        );
      case COLUMN_TYPE_SLUGS.TEXT_COPY:
        return (row) => (
          <TextWithCopy
            data-tag="allowRowEvents"
            value={fetchValueByDotOperator(row, columnKey)}
            textStyle={{ whiteSpace: 'pre-wrap' }}
          />
        );
      case COLUMN_TYPE_SLUGS.STATUS:
        return (row) => <div data-tag="allowRowEvents">{getStatusColValue(activeCol, row, columnKey)}</div>;
      case COLUMN_TYPE_SLUGS.ARRAY:
        return (row) => <div data-tag="allowRowEvents">{getArrayColValue(row, columnKey)}</div>;
      case COLUMN_TYPE_SLUGS.DATE:
        return (row) => (
          <div data-tag="allowRowEvents">
            {fetchValueByDotOperator(row, columnKey) ? DisplayDateTime(fetchValueByDotOperator(row, columnKey)) : '-'}
          </div>
        );
      default:
        return (row) => (
          <div data-tag="allowRowEvents" style={{ whiteSpace: 'pre-wrap' }}>
            {fetchValueByDotOperator(row, columnKey) ? fetchValueByDotOperator(row, columnKey) : '-'}
          </div>
        );
    }
  };

  const getColData = (activeCol = {}) => {
    return {
      name: activeCol?.name,
      selector: getColSelector(activeCol),
      cell: getColCell(activeCol),
      ...getColMinWidth(activeCol),
      ...getColSortFields(activeCol),
    };
  };

  const filterActiveColumns = (activeCols = []) => {
    const filteredColumns = [];
    if (activeCols?.length) {
      activeCols?.forEach((activeCol) => {
        if (activeCol?.active) {
          filteredColumns.push(getColData(activeCol));
        }
      });
    }

    const actionColumn = getActions(actionsList);

    if (!actionColumn) return filteredColumns;

    return [...filteredColumns, actionColumn];
  };

  useEffect(() => {
    if (activeColumns?.length) {
      setTableColumns(filterActiveColumns(activeColumns));
    }
  }, [activeColumns]);

  return {
    tableColumns,
  };
};

export default useTableColumns;
