import React, { Fragment } from 'react';
import cx from 'clsx';
import RenderFilters from '../render-filters/RenderFilters';
import styles from './AdvancedFilters.module.scss';
import { Empty, Flex } from 'antd';
import { Button } from '../../../common';
import { showResetButton } from '../../../utils/filters-util';

const AdvancedFilters = ({
  baseFilters = [],
  location,
  navigate,
  setFiltersSidebarOpen = () => {},
  handleFiltersChange = () => {},
  filtersObject,
  setFiltersObject = () => {},
}) => {
  const resetFilters = () => {
    navigate({
      pathname: location.pathname,
      search: '',
    });
    setFiltersSidebarOpen(false);
  };

  return (
    <Flex vertical style={{ minHeight: '100%' }}>
      {baseFilters.length > 0 ? (
        <>
          <Flex vertical gap={24} className={cx(styles.advanceFilters)}>
            {baseFilters?.length &&
              baseFilters.map((item, index) => (
                <Fragment key={index}>
                  <RenderFilters
                    item={item}
                    filtersObject={filtersObject}
                    setFiltersObject={setFiltersObject}
                    handleFiltersChange={handleFiltersChange}
                  />
                </Fragment>
              ))}
          </Flex>
          <Flex align="center" justify="end" gap={8} className={styles.buttons}>
            {showResetButton(location) && <Button text={'Reset Filters'} onClick={resetFilters} />}
          </Flex>
        </>
      ) : (
        <Empty />
      )}
    </Flex>
  );
};

export default AdvancedFilters;
