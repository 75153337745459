import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchProductsApi } from '../../api/products-api';

const initialState = {
  activeColumns: [],
  products: [],
  loading: true,
  pending: false,
  pagination: {},
};

export const fetchProductsListing = createAsyncThunk('products/listing', async (updatedParamsObject = {}) => {
  try {
    const paramsObj = {
      ...updatedParamsObject,
    };
    const res = await fetchProductsApi(paramsObj);
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

const ProductsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
    setProductsActiveColumn: (state, action) => {
      state.activeColumns = action?.payload?.activeColumns;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductsListing.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchProductsListing.fulfilled, (state, action) => {
        if (state.loading) {
          //means first time loading
          state.activeColumns = action?.payload?.active_columns || [];
        }
        state.products = action?.payload?.data || [];
        state.loading = false;
        state.pending = false;
        state.pagination = action?.payload?.meta?.pagination || {};
      })
      .addCase(fetchProductsListing.rejected, (state) => {
        state.loading = false;
        state.pending = false;
      });
  },
});

export const { setProductsActiveColumn, setProductsLoading } = ProductsSlice.actions;
export default ProductsSlice.reducer;
