import { DatePicker } from 'antd';
import cx from 'clsx';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { DATE_RANGE_PRESETS } from '../../constants/date-constants';
import ErrorMessage from '../error-message/ErrorMessage';
import Label from '../label/Label';
import Skeleton from '../skeleton/Skeleton';
import styles from './DateRangePicker.module.scss';

const { RangePicker: AntdDatePicker } = DatePicker;
dayjs.extend(weekday);
dayjs.extend(localeData);

const DateRangePicker = ({
  startDate,
  endDate,
  picker = 'day',
  loading = false,
  showRangePreset = false,
  rangePresets = [],
  allowEmpty = [false, false],
  format = 'DD/MM/YY',
  onChange = () => {},
  onClear = () => {},
  filterSelected,
  showTime = false,
  disabled = false,
  placeholder = '',
  label = '',
  labelSize = 'small',
  labelClassName = '',
  required = false,
  errorMsg,
  fullPlaceholder,
  size = 'large',
  disableFutureDates = false,
  className = '',
}) => {
  const handleClear = () => {
    onClear && onClear();
  };

  const disabledDate = (current) => {
    return disableFutureDates ? current && current > dayjs().endOf('day') : false;
  };

  if (loading) return <Skeleton height={40} />;

  return (
    <div className={styles.dateContainer}>
      {label && (
        <Label size={labelSize} className={labelClassName} required={required}>
          {label}
        </Label>
      )}
      <AntdDatePicker
        placeholder={fullPlaceholder ? fullPlaceholder : [placeholder, '']}
        separator={startDate && endDate ? '' : ''}
        presets={showRangePreset ? (rangePresets.length > 0 ? rangePresets : DATE_RANGE_PRESETS) : []}
        inputReadOnly={true}
        allowClear={onClear}
        showTime={showTime}
        value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
        disabled={disabled}
        picker={picker}
        allowEmpty={allowEmpty}
        format={format}
        style={{
          color: filterSelected,
          fontWeight: filterSelected ? 'bold' : '',
        }}
        className={cx(styles.dateRange, className)}
        size={size}
        onChange={(value, dateStrings) => {
          if (value) {
            const startDateValue = value[0];
            const endDateValue = value[1];
            onChange(startDateValue, endDateValue);
          } else if (!value) {
            handleClear();
          }
        }}
        disabledDate={disabledDate}
      />
      {errorMsg && <ErrorMessage errorMsg={errorMsg} />}
    </div>
  );
};

export default DateRangePicker;
