import React from 'react';
import { Drawer as AntdDrawer } from 'antd';
import styles from './Sidebar.module.scss';
import { IoCloseOutline } from 'react-icons/io5';

const Sidebar = ({
  setSidebarOpen = () => {},
  sidebarOpen = false,
  placement = 'right',
  content = '',
  title = true,
  closeIcon = false,
  heading = '',
  description = '',
  sidebarWidth = 450,
  footer,
  loading = false,
  ...rest
}) => {
  return (
    <AntdDrawer
      placement={placement}
      width={sidebarWidth}
      open={sidebarOpen}
      loading={loading}
      onClose={() => setSidebarOpen(false)}
      footer={footer}
      className={''}
      title={
        title && (
          <>
            {heading && <div className={styles.sidebarHeading}>{heading}</div>}
            {description && <div className={styles.sidebarDesc}>{description}</div>}
          </>
        )
      }
      extra={
        <IoCloseOutline color="#9D9D9D" size={20} style={{ cursor: 'pointer' }} onClick={() => setSidebarOpen(false)} />
      }
      closeIcon={closeIcon}
      {...rest}
    >
      {content}
    </AntdDrawer>
  );
};

export default Sidebar;
