export const filterFieldFormat = (string) => {
  return string?.replaceAll('%20', ' ');
};

export const formatOptionsForSelect = ({ list = [], setAsSlug = false }) => {
  return list?.map((item) => {
    return {
      ...item,
      value: setAsSlug ? item?.slug : item?.id,
      label: item?.name ?? item?.title ?? item?.value,
      name: item?.name ?? item?.title ?? item?.value,
      slug: setAsSlug ? item?.slug : item?.id,
    };
  });
};

export const formatValueForSelect = ({ obj = null, key = 'name' }) => {
  return obj ? { ...obj, label: obj?.[key] } : null;
};

export const mapQueryStringToFilterObject = (search, cb = () => {}, setFilterChips) => {
  const queryObj = {},
    queryString = search?.split('?')[1];
  if (queryString) {
    const items = queryString.split('&');
    for (let item of items) {
      if (item.split('=')[0] !== 'widget_for') {
        const key = item.split('=')[0],
          value = item.split('=')[1];
        cb(key, filterFieldFormat(value));
        if (key && value) {
          if (queryObj[key]) {
            queryObj[key] += ',' + filterFieldFormat(value);
          } else {
            queryObj[key] = filterFieldFormat(value);
          }
        }
      }
    }
  } else {
    !!setFilterChips && setFilterChips(null);
  }
  return { queryObj };
};

export const convertQueryObjToString = (obj, encode = false) => {
  let keys = Object.keys(obj);
  if (keys?.length) {
    return keys
      .map((key) =>
        typeof obj[key] !== 'number' && obj[key]?.indexOf(',') !== -1
          ? obj[key]
              ?.split(',')
              .map((e) => key + '=' + e)
              .join('&')
          : obj[key]
            ? key + '=' + (encode ? encodeURI(obj[key]) : obj[key])
            : '',
      )
      ?.filter((item) => !!item)
      ?.join('&');
  } else {
    return '';
  }
};

export const getQueryString = (filters = {}, noStart = false) => {
  let queryString = '';
  if (filters) {
    queryString = `${noStart ? '' : '?'}` + convertQueryObjToString(filters, true);
  }
  return queryString;
};

export const getFiltersArray = (filtersList) => {
  const advancedFilters = [];
  const filters = [];
  filtersList.forEach((filter) => {
    if (filter.isAdvancedFilter) {
      advancedFilters.push(filter);
    } else {
      filters.push(filter);
    }
  });

  return { filters, advancedFilters };
};

export const showResetButton = (location) => {
  // Counts the unique keys from location.search
  const { queryObj } = mapQueryStringToFilterObject(location.search);
  const keys = [];

  Object.keys(queryObj).forEach((key) => {
    key.split('[').map((item) => {
      if (!item.includes('lte') && !item.includes('gte')) {
        keys.push(item);
      }
    });
  });
  const uniqueKeys = new Set(keys.filter((item) => item.length > 1));
  return uniqueKeys.size > 1;
};

export const findQuickLink = ({ location, quickLinks }) => {
  if (!location?.search || !quickLinks?.length > 0) return null;

  return quickLinks?.find((link) => link?.body === `${location?.pathname}${location?.search}`);
};

export const removeChildFilter = (baseList, parentSearchKey, obj, parentValue, setSlugAsKey) => {
  const index = baseList?.findIndex((e) => e?.key === parentSearchKey);
  if (!baseList[index]?.child || !baseList[index]?.child?.length) return;

  baseList[index].child.forEach((childFilterKey) => {
    if (childFilterKey) {
      const childFiltersToDelete = baseList
        ?.find((e) => e.key === childFilterKey)
        ?.list?.filter((e) => e.parentId && !e.header && e.parentId.toString() === parentValue.toString());

      childFiltersToDelete &&
        childFiltersToDelete.forEach((e) => {
          removeChildFilter(baseList, childFilterKey, obj, setSlugAsKey ? e?.slug : e?.id, setSlugAsKey);
          removeFilter(obj, childFilterKey, setSlugAsKey ? e?.slug : e?.id);
        });
    }
  });
};

export const removeFilter = (obj, searchKey, value) => {
  if (obj[searchKey]) {
    const array = obj[searchKey].split(',');
    const index = array.indexOf(value.toString());
    if (index > -1) {
      if (array.length > 1) {
        array.splice(index, 1);
        obj[searchKey] = array.join(',');
      } else {
        delete obj[searchKey];
      }
    }
  }
};

export const removeFilterOnDemand = (obj, searchKey) => {
  if (obj[searchKey]) {
    if (searchKey.includes('[lte]')) {
      delete obj[searchKey];
      delete obj[searchKey.replace('[lte]', '[gte]')];
    } else if (searchKey.includes('[gte]')) {
      delete obj[searchKey];
      delete obj[searchKey.replace('[gte]', '[lte]')];
    }
    if (obj.hasOwnProperty(searchKey)) {
      delete obj[searchKey];
    }
  }
};
