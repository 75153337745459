import { lazy } from 'react';

//Dashboard
export const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ './dashboard/dashboard.jsx'));

//Leads
export const Leads = lazy(() => import(/*webpackPrefetch: true,  webpackChunkName: "leads" */ './leads/index.js'));
export const LeadDetail = lazy(
  () => import(/*webpackPrefetch: true,  webpackChunkName: "lead-detail" */ './leads/detail.js'),
);

//Patients
export const Patients = lazy(
  () => import(/*webpackPrefetch: true,  webpackChunkName: "patients" */ './patients/index.js'),
);
export const PatientEdit = lazy(
  () =>
    import(
      /*webpackPrefetch: true,  webpackChunkName: "patient-edit" */ './patients/patient-info/patient-edit/PatientEdit.jsx'
    ),
);
export const PatientCreate = lazy(
  () => import(/* webpackChunkName: "patient-create" */ './patients/patient-info/patient-create/PatientCreate.jsx'),
);

//Orders
export const Orders = lazy(() => import(/*webpackChunkName: "orders" */ './orders/index.js'));

//Cases
export const Cases = lazy(() => import(/*webpackChunkName: "cases" */ './cases/index.js'));
export const CaseDetail = lazy(() => import(/*webpackChunkName: "case-detail" */ './cases/detail.js'));

//Statuses
export const Statuses = lazy(() => import(/*webpackChunkName: "statuses" */ './statuses/index.js'));
export const StatusEdit = lazy(
  () => import(/*webpackChunkName: "status-edit" */ './statuses/status-info/status-edit/StatusEdit.jsx'),
);

//Products
export const Products = lazy(() => import(/*webpackChunkName: "products" */ './products/index.jsx'));

//Subscriptions
export const Subscriptions = lazy(() => import(/*webpackChunkName: "subscriptions" */ './subscriptions/index.jsx'));

//Users
export const Users = lazy(() => import(/*webpackChunkName: "users" */ './users/index.jsx'));

//Tickets
export const Tickets = lazy(() => import(/*webpackChunkName: "tickets" */ './tickets/index.jsx'));

//Questionnaires
export const Questionnaires = lazy(() => import(/*webpackChunkName: "questionnaires" */ './questionnaires/index.jsx'));

//Vouchers
export const Vouchers = lazy(() => import(/*webpackChunkName: "vouchers" */ './vouchers/index.jsx'));
