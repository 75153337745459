import React, { useEffect, useState } from 'react';
import { Layout, Menu, Typography, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { SIDE_MENU_WIDTH } from '../../constants/generic-constants';
import { menu_items } from './side-menu-constants';
import styles from './SideMenu.module.scss';
import { Avatar } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../reducers/selectors';
import { useAuthContext } from '../../contexts/auth-context';
import { removeAppCookies } from '../../utils/cookies-util';
import { logoutAction } from '../../reducers/logout-action';
import toast from 'react-hot-toast';
import { persistor } from '../../store';
import { usePermissions } from '../../hooks';
import { logoutApi } from '../../api/auth-api';
import { getErrorString } from '../../utils';

const { SubMenu } = Menu;
const { Sider } = Layout;
const { Text } = Typography;

const SideMenu = () => {
  const userData = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getPermission } = usePermissions();
  const [stateOpenKeys, setStateOpenKeys] = useState([]);
  const [sideMenuWidth, setSideMenuWidth] = useState(SIDE_MENU_WIDTH.close);
  const [loadingLogout, setLoadingLogout] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState([]);
  const location = useLocation();
  const menuItems = menu_items.filter((menu_item) =>
    menu_item?.permkey ? getPermission({ models: menu_item.permkey, actions: 'index' }) : true,
  );
  const { handleLogout } = useAuthContext();

  const onClickLogout = async () => {
    try {
      setLoadingLogout(true);
      const res = await logoutApi();
      if (res && res?.status) {
        removeAppCookies();
        persistor.purge();
        dispatch(logoutAction());
        toast.success('Logout Successful!');
        handleLogout();
        navigate(`/signin`, { replace: true });
      } else {
        toast.error(getErrorString(res));
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoadingLogout(false);
    }

    // removeAppCookies();
    // persistor.purge();
    // dispatch(logoutAction());
    // toast.success('Logout Successful!');
    // handleLogout();
    // navigate(`/signin`, { replace: true });
  };

  useEffect(() => {
    const arr = location.pathname.split('/').filter(Boolean);
    const [parent = '', child = ''] = arr.slice(0, 2);

    setCurrent([`/${parent}`]);
    // const formattedParentName = makeTitleCase({ string: parent, seperator: '-' }).replace('-', ' ');
    // setStateOpenKeys([formattedParentName]);
  }, [location, sideMenuWidth]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={styles.sideMenuWrap}>
      <Sider
        collapsible={true}
        theme="light"
        width={276}
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        trigger={null}
      >
        {!collapsed && (
          <div className={styles.sideMenuTitle} style={{ opacity: collapsed ? 0 : 1 }}>
            Elevate
          </div>
        )}
        <Menu
          mode={'inline'}
          style={{ height: collapsed ? 'calc(100vh - 100px)' : 'calc(100vh - 190px)', paddingTop: '1rem' }}
          inlineIndent={30}
          items={menuItems}
          selectedKeys={current}
        />
        <div style={{ position: 'absolute', bottom: 0, width: '100%', overflowX: 'hidden' }}>
          <Menu mode="vertical" selectable={false} style={{ borderTop: '1px solid #f0f0f0', overflowY: 'hidden' }}>
            <SubMenu
              key="user"
              disabled={loadingLogout}
              icon={<Avatar size={40} name={userData?.name} />}
              title={
                !collapsed && (
                  <div className={styles.userInfo}>
                    <Text strong>{userData?.name}</Text>
                    <Text style={{ fontSize: '12px', color: '#888' }}>{userData?.email}</Text>
                  </div>
                )
              }
            >
              <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={onClickLogout}>
                Logout
              </Menu.Item>
            </SubMenu>
          </Menu>
          <div className={styles.sideMenuToggle}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={toggleCollapsed}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </Sider>
    </div>
  );
};

export default SideMenu;
