import dayjs from 'dayjs';
import { isNumericString } from '..';
import { DATE_FORMATS } from '../../constants/date-constants';
import { mapQueryStringToFilterObject } from '../filters-util';

const getChipObject = ({ chipInfo, key, value }) => {
  const { type = '', displayName = '', options = [], mode = 'multiple', setAsSlug = false } = chipInfo[key];
  switch (type) {
    case 'input':
      return { chipValue: value, chipName: displayName, seperator: ', ' };
    case 'date':
      return {
        chipValue: dayjs(value).format(DATE_FORMATS.dayMonthYearFilters),
        chipName: displayName,
        seperator: ' - ',
      };
    case 'dropdown':
      let dropdownValue = value;
      if (isNumericString(value)) dropdownValue = parseInt(value);
      return {
        chipValue:
          mode === 'multiple'
            ? options
                ?.filter((item) => value.includes(setAsSlug ? item?.slug : item?.id))
                ?.map((item) => item?.name)
                .join(', ')
            : options?.find((item) => (setAsSlug ? item?.slug === dropdownValue : item?.id === dropdownValue))?.name,
        chipName: displayName,
        seperator: ', ',
      };
    default:
      return '';
  }
};

const getChipInfo = (filtersList) => {
  const chipInfo = {};
  filtersList?.map((filter) => {
    if (filter.type === 'date' || filter.type === 'range' || filter.type === 'boolean-range') {
      if (filter?.keys) {
        chipInfo[`q[${filter?.keys[0]}_gteq]`] = {
          type: filter?.type,
          displayName: filter?.placeholder,
        };
        chipInfo[`q[${filter?.keys[1]}_lteq]`] = { type: filter?.type, displayName: filter?.placeholder };
      } else {
        if (filter?.type) {
          chipInfo[`q[${filter?.key}_gteq]`] = {
            type: filter?.type,
            displayName: filter?.placeholder,
          };
          chipInfo[`q[${filter?.key}_lteq]`] = { type: filter?.type, displayName: filter?.placeholder };
        }

        chipInfo[`q[${filter?.key}_gt${filter.type !== 'boolean-range' ? 'eq' : ''}]`] = {
          type: filter?.type,
          displayName: filter?.placeholder,
          ...(filter.type === 'boolean-range' && { options: filter?.options }),
        };
        chipInfo[`f[${filter?.key}][lte]`] = {
          type: filter?.type,
          displayName: filter?.placeholder,
          ...(filter.type === 'boolean-range' && { options: filter?.options }),
        };
      }
    } else {
      chipInfo[filter?.key] = {
        type: filter?.type,
        displayName: filter?.placeholder,
        options: filter?.options,
        mode: filter?.mode,
        setAsSlug: filter?.setAsSlug,
      };
    }
  });
  return chipInfo;
};

export const handleFilterChips = ({ location, filtersList }) => {
  const chipInfo = getChipInfo(filtersList);
  const filterCh = [];
  const obj = {};
  const chipMap = {};

  mapQueryStringToFilterObject(location.search, (key, value) => {
    if (!chipInfo[key]) return;
    const chipObject = getChipObject({ chipInfo, key, value });

    if (!obj[chipObject.chipName]) {
      chipMap[chipObject.chipName] = key;
      obj[chipObject.chipName] = `${chipObject.chipValue}`;
    } else {
      const existingValues = obj[chipObject.chipName].split(chipObject.seperator).map((val) => val.trim());
      const newValue = `${chipObject.chipValue}`;

      existingValues.push(newValue);

      obj[chipObject.chipName] = existingValues.join(chipObject.seperator);
    }
  });

  Object.keys(obj).forEach((element) => {
    const space = '\n';
    if (obj[element]) {
      filterCh.push({
        title: (
          <>
            <span style={{ color: 'var(--gray-500)' }}>
              {element}:{space}
            </span>
            <span style={{ fontWeight: 'var(--ant-font-weight-strong)' }}>{obj[element]}</span>
          </>
        ),
        searchKey: chipMap[element],
        searchValue: obj[element],
        location,
      });
    }
  });
  return filterCh;
};
